import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import type { GoogleMapProps, Libraries } from '@react-google-maps/api';
import { useTheme } from '@mui/material';
import { LoadingSpinner } from '.';
import { GOOGLE_MAPS_API_KEY } from '../config';
import { mapDarkTheme, mapLightTheme } from '../utils/theme';

const googleMapsLibraries: Libraries = ['places'];

const GoogleMapsBase = ({ children, ...props }: GoogleMapProps) => {
  const theme = useTheme();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: googleMapsLibraries,
  });

  if (!isLoaded) {
    return <LoadingSpinner />;
  }

  return (
    <GoogleMap
      {...props}
      options={{
        styles: theme.palette.mode === 'dark' ? mapDarkTheme : mapLightTheme,
        ...props.options,
      }}
    >
      {children}
    </GoogleMap>
  );
};

export default GoogleMapsBase;
