import { ApolloClient, ApolloProvider, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as Sentry from '@sentry/react';
import { createUploadLink } from 'apollo-upload-client';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { get } from 'lodash';
import { ErrorBoundary } from 'react-error-boundary';
import { CustomMuiThemeProvider, ErrorFallback, InstallPWADialog, UpdatePWADialog } from './components';
import SnackbarProvider from './components/snackbar_provider';
import { getApolloInternalErrorGql } from './components/use_throw_apollo_error';
import { APP_NAME, APP_VERSION } from './config';
import { AppProvider } from './contexts/app_context';
import RootRouter from './routes/root_router';
import tokenManager from './utils/token_manager';

import type { apolloInternalErrorType } from './components/use_throw_apollo_error';

// Sentry
Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DNS,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
});

// Firebase
const firebase: FirebaseApp = initializeApp({
  apiKey: 'AIzaSyAKML13AIuPFvDjjfxraMCnBwRS_JL6ckc',
  authDomain: 'findtemp.firebaseapp.com',
  databaseURL: 'https://findtemp.firebaseio.com',
  projectId: 'findtemp',
  storageBucket: 'findtemp.appspot.com',
  messagingSenderId: '1060316819101',
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
});

// Register FCM+PWA service worker
navigator.serviceWorker.register('/sw.js', { scope: '/' });

// dayjs
dayjs.extend(duration);
dayjs.extend(isSameOrBefore);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime, {
  thresholds: [
    { l: 's', r: 1 },
    { l: 'm', r: 1 },
    { l: 'mm', r: 59, d: 'minute' },
    { l: 'h', r: 1 },
    { l: 'hh', r: 23, d: 'hour' },
    { l: 'd', r: 1 },
    { l: 'dd', r: 29, d: 'day' },
    { l: 'M', r: 1 },
    { l: 'MM', r: 11, d: 'month' },
    { l: 'y', r: 1 },
    { l: 'yy', d: 'year' },
  ],
});

// Apollo
const authLink = setContext(async (_, { headers }) => {
  const token = await tokenManager.getToken();
  return {
    headers: {
      ...headers,
      'Authorization': `Bearer ${token}`,
      'App-Name': APP_NAME,
      'App-Version': APP_VERSION,
    },
  };
});

const httpLink = createUploadLink({
  uri: import.meta.env.VITE_API_GRAPHQL_URL,
  credentials: 'same-origin',
});

const errorLink = onError(({ operation, networkError, graphQLErrors }) => {
  if (networkError) {
    const statusCode: number | undefined = get(networkError, 'statusCode');

    if (statusCode === 401 && get(networkError, ['result', 'error']) === 'access_token.invalid') {
      // TODO
      // tokenManager.getToken().then(async (token) => {
      //   // isLogin
      //   if (!isEmpty(token)) {
      //     await Promise.all([tokenManager.clearTokens(), AsyncStorage.clear()]);
      //     // Exit App and Send Error Notification
      //     await Notifications.scheduleNotificationAsync({
      //       content: { title: 'เกิดข้อผิดพลาด', body: 'เกิดข้อผิดพลาด กรุณาล็อคอินใหม่อีกครั้ง' },
      //       trigger: null,
      //       identifier: 'APOLLO_TOKEN_INVALID',
      //     });
      //     await ExpoUpdates.reloadAsync();
      //   }
      // });
    }

    if (statusCode === 503 || get(networkError, 'message') === 'Failed to fetch') {
      const { cache } = operation.getContext();

      const apolloInternalError: apolloInternalErrorType = {
        __typename: 'apolloInternalError',
        message: get(networkError, 'message', null),
        statusCode: statusCode ?? -1,
        networkError: networkError ?? null,
        graphQLErrors: graphQLErrors ?? null,
      };

      if (cache?.writeQuery) {
        cache.writeQuery({
          query: getApolloInternalErrorGql,
          data: { apolloInternalError },
        });
      }
    }
  }
});

const retryLink = new RetryLink({
  attempts: {
    max: 5,
    retryIf: (error) => !!error && error.statusCode !== 401,
  },
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: import.meta.env.RUN_ENV === 'development',
  link:
    import.meta.env.MODE === 'development'
      ? from([authLink, errorLink, httpLink])
      : from([authLink, errorLink, retryLink, httpLink]),
});

const App = () => (
  <AppProvider>
    <ApolloProvider client={client}>
      <CustomMuiThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline enableColorScheme />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <SnackbarProvider>
              <RootRouter />

              <InstallPWADialog />

              <UpdatePWADialog />
            </SnackbarProvider>
          </ErrorBoundary>
        </LocalizationProvider>
      </CustomMuiThemeProvider>
    </ApolloProvider>
  </AppProvider>
);

export default App;
