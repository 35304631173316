import React from 'react';

import { gql, useMutation } from '@apollo/client';
import { Close, Star } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Box,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { filter, get, isEmpty, isNil, isNumber, map } from 'lodash';
import numeral from 'numeral';
import type { DialogProps } from '@mui/material';
import { LoadingButton } from '../../../components';
import { CandidateCardFragment } from '../../../graphql/fragments';
import { createScopedI18n, i18n } from '../../../i18n/i18n';
import { formatDate, joinPairs } from '../../../utils/libs';
import { getJobApplicantsV2Gql } from './job_candidate_list';
import { getJobHiringsV2Gql } from './job_hire_list';

import type { StudentCandidateType } from './student_candidate_card';

const jobDetailPageI18n = createScopedI18n('pages.jobs_id');
const studentFieldI18n = createScopedI18n('graphql.fields.student');

const acceptAppliedStudentGql = gql(`
  mutation acceptAppliedStudent($jobApplicantId: ID!) {
    acceptAppliedStudent(jobApplicantId: $jobApplicantId) {
      success
      errors
    }
  }
`);

const dislikeAppliedStudentGql = gql(`
  mutation dislikeAppliedStudent($jobApplicantId: ID!) {
    dislikeAppliedStudent(jobApplicantId: $jobApplicantId) {
      success
      errors
    }
  }
`);

export type StudentCandidateDetailDialogProps = {
  student: StudentCandidateType | null;
  restaurantChargePerHour?: number;
  jobApplicantId?: number | string;
  studentExperienceBadges?: { isShow: boolean; label: string }[];
  studentPreExperienceBadges?: { isShow: boolean; label: string }[];
  studentCertificateBadges?: { isShow: boolean; label: string }[];
  disableDetailDialogAction?: boolean;
  errorMessage?: string | null;
  onErrorMessageChange?: (errorMessage: string | null, errorsPairs?: [string, string][] | null) => void;
} & DialogProps;

const StudentCandidateDetailDialog = ({
  student,
  studentExperienceBadges,
  studentPreExperienceBadges,
  studentCertificateBadges,
  restaurantChargePerHour,
  errorMessage,
  jobApplicantId,
  disableDetailDialogAction,
  onClose,
  onErrorMessageChange,
  ...props
}: StudentCandidateDetailDialogProps) => {
  const theme = useTheme();

  const [internalErrorMessage, setInternalErrorMessage] = React.useState<string | null>(errorMessage ?? null);
  const onErrorMessageChangeHandler = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (errorMessage: string | null, errorsPairs?: [string, string][] | null) => {
      if (onErrorMessageChange) {
        onErrorMessageChange(errorMessage, errorsPairs);
      } else {
        setInternalErrorMessage(errorMessage);
      }
    },
    [onErrorMessageChange],
  );

  React.useEffect(() => {
    setInternalErrorMessage(errorMessage ?? null);
  }, [errorMessage]);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [acceptAppliedStudentMutation] = useMutation(acceptAppliedStudentGql);

  const acceptAppliedStudentHandler = React.useCallback(async () => {
    if (isEmpty(jobApplicantId)) {
      return;
    }

    setIsSubmitting(true);
    onErrorMessageChangeHandler(null);
    try {
      const { data } = await acceptAppliedStudentMutation({
        variables: { jobApplicantId },

        // Update cache after mutation
        // * 1. Update JobApplicant status
        // * 2. Move accepted JobApplicant from jobApplicants to jobHirings
        // * 3. Update DateJobCard job.jobApplicants and job.jobHirings
        update: (cache, { data: mutationResult }) => {
          if (!mutationResult?.acceptAppliedStudent?.success) return;

          const jobApplicantRef = cache.identify({ __typename: 'JobApplicant', id: jobApplicantId });
          // Read current jobApplicant from cache
          const acceptedJobApplicant: {
            id: string;
            status: string;
            restaurantChargePerHour: number;
            student: unknown;
            job: { id: string };
          } | null = cache.readFragment({
            id: jobApplicantRef,
            fragment: CandidateCardFragment,
          });

          if (isEmpty(acceptedJobApplicant)) return;

          // 1. Update jobApplicant status to 'match'
          cache.modify({
            id: jobApplicantRef,
            fields: { status: () => 'match' },
          });

          // 2. Move accepted JobApplicant from jobApplicants to jobHirings
          // New optimistic JobHiring based on jobApplicant
          const optimisticJobHiring = {
            ...acceptedJobApplicant,
            __typename: 'JobHiring',
            id: `__JobApplicant:${jobApplicantId}`,
            status: 'match',
          };

          // -  2.1 Remove jobApplicant from jobApplicants list
          cache.updateQuery(
            { query: getJobApplicantsV2Gql, variables: { jobId: acceptedJobApplicant.job.id } },
            (prevData) => {
              const nextData = {
                ...prevData,
                jobApplicantsV2: filter(prevData?.jobApplicantsV2, (applicant) => applicant.id !== jobApplicantId),
              };
              return nextData;
            },
          );

          // -  2.2 Add jobApplicant as jobHiring to jobHirings list
          cache.updateQuery(
            { query: getJobHiringsV2Gql, variables: { jobId: acceptedJobApplicant.job.id } },
            (prevData) => {
              const nextData = {
                ...prevData,
                jobHiringsV2: [...(prevData?.jobHiringsV2 ?? []), optimisticJobHiring],
              };
              return nextData;
            },
          );

          // 3. Add jobApplicant as jobHiring to job and update job status
          cache.modify({
            id: cache.identify({ __typename: 'Job', id: acceptedJobApplicant.job.id }),
            fields: {
              jobHirings(prevJobHirings = []) {
                return [...prevJobHirings, optimisticJobHiring];
              },
              numberOfHired: (prevNumberOfHired: number) => prevNumberOfHired + 1,
              status: (prevStatus: string, { readField, DELETE }) => {
                const prevNumberOfHired = readField('numberOfHired');
                const prevNumberOfPosition = readField('numberOfPosition');

                if (isNumber(prevNumberOfHired) && isNumber(prevNumberOfPosition)) {
                  return prevNumberOfHired + 1 >= prevNumberOfPosition ? 'full' : prevStatus;
                }
                return DELETE; // Cannot determine status, yeet the cache to force refetch
              },
            },
          });
        },
      });

      if (data?.acceptAppliedStudent.success) {
        if (onClose) onClose({}, 'backdropClick');
      } else {
        const errors = data?.acceptAppliedStudent.errors;
        const acceptErrorMessage = joinPairs(errors);
        onErrorMessageChangeHandler(acceptErrorMessage, errors);
      }
    } catch (error) {
      const acceptErrorMessage = get(error, 'message', null);
      onErrorMessageChangeHandler(acceptErrorMessage);
    }

    setIsSubmitting(false);
  }, [acceptAppliedStudentMutation, jobApplicantId, onClose, onErrorMessageChangeHandler]);

  const [dislikeAppliedStudentMutation] = useMutation(dislikeAppliedStudentGql);
  const dislikeAppliedStudentHandler = React.useCallback(async () => {
    if (isEmpty(jobApplicantId)) {
      return;
    }

    setIsSubmitting(true);
    onErrorMessageChangeHandler(null);
    try {
      // TODO: CACHE Update /jobs, /job_hirings, /jobs/:id
      const { data } = await dislikeAppliedStudentMutation({
        variables: { jobApplicantId },
        refetchQueries: ({ data: dislikeData }) =>
          dislikeData?.dislikeAppliedStudent.success ? ['GetJobApplicantsV2', 'GetJobHiringsV2', 'GetJobDetail'] : [],
      });

      if (data?.dislikeAppliedStudent.success) {
        if (onClose) onClose({}, 'backdropClick');
      } else {
        const errors = data?.dislikeAppliedStudent.errors;
        const dislikeErrorMessage = joinPairs(errors);
        onErrorMessageChangeHandler(dislikeErrorMessage, errors);
      }
    } catch (error) {
      const dislikeErrorMessage = get(error, 'message', null);
      onErrorMessageChangeHandler(dislikeErrorMessage);
    }

    setIsSubmitting(false);
  }, [dislikeAppliedStudentMutation, jobApplicantId, onClose, onErrorMessageChangeHandler]);

  return (
    <Dialog fullWidth onClose={onClose} {...props}>
      <DialogTitle display="inline-flex" alignItems="center" gap={1}>
        <Typography flex={1} variant="inherit">
          {i18n.t('general.detail')}
        </Typography>
        <IconButton onClick={(event) => onClose && onClose(event, 'backdropClick')}>
          <Close />
        </IconButton>
      </DialogTitle>

      {isEmpty(student) ? (
        <DialogContent>
          <DialogContentText>{i18n.t('general.empty')}</DialogContentText>
        </DialogContent>
      ) : (
        <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="h5" color="primary" textAlign="center">
            {jobDetailPageI18n('temp_name', { name: student.nickName })}
          </Typography>
          <Stack direction="row" justifyContent="center" gap={1}>
            <Avatar src={student.avatar} sx={{ width: 96, height: 96 }} />
            <Stack gap={1} justifyContent="flex-end">
              {student.isWorkedHere && (
                <Box>
                  <Chip size="small" color="warning" icon={<Star />} label={jobDetailPageI18n('been_work_here')} />
                </Box>
              )}
              {restaurantChargePerHour && (
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  alignSelf="start"
                  border={1}
                  borderRadius={1}
                  borderColor="primary.main"
                  color="primary.main"
                  paddingX={1.5}
                  paddingY={0.5}
                  gap={1}
                >
                  <Typography variant="h5">{numeral(restaurantChargePerHour).format('0,0')}</Typography>
                  <Typography variant="caption" textAlign="end">
                    {jobDetailPageI18n('credit_pre')}
                    <br />
                    {jobDetailPageI18n('pre_hour')}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>

          <Box>
            <Typography color="primary">{studentFieldI18n('headline')}</Typography>
            <Typography variant="body2" whiteSpace="break-spaces">
              {student.headline ?? '-'}
            </Typography>
          </Box>

          <Box>
            <Typography color="primary">{studentFieldI18n('student_covid19_vaccine')}</Typography>
            <Typography variant="body2">
              {isEmpty(student.lastStudentCovid19Vaccine)
                ? '-'
                : `${student.lastStudentCovid19Vaccine.nth} ${i18n.t('general.when')} ${formatDate(student.lastStudentCovid19Vaccine.vaccinedAt)}`}
            </Typography>
          </Box>

          <Box>
            <Typography color="primary">{studentFieldI18n('student_certificate')}</Typography>
            {isEmpty(filter(studentCertificateBadges, 'isShow')) ? (
              <Typography variant="body2">{jobDetailPageI18n('student_certificate_empty')}</Typography>
            ) : (
              map(
                studentCertificateBadges,
                ({ isShow, label }, index) =>
                  isShow && (
                    <Typography key={index} variant="body2">
                      {label}
                    </Typography>
                  ),
              )
            )}
          </Box>

          <Box>
            <Typography color="primary">{studentFieldI18n('student_pre_experience')}</Typography>
            {isEmpty(filter(studentPreExperienceBadges, 'isShow')) ? (
              <Typography variant="body2">{jobDetailPageI18n('student_pre_experience_empty')}</Typography>
            ) : (
              map(
                studentPreExperienceBadges,
                ({ isShow, label }, index) =>
                  isShow && (
                    <Typography key={index} variant="body2">
                      {label}
                    </Typography>
                  ),
              )
            )}
          </Box>

          <Box>
            <Typography color="primary">{studentFieldI18n('student_experience')}</Typography>
            <Typography variant="body2">
              {studentFieldI18n('rating')}
              {': '}
              {(student.rating ?? 0) > 0 ? (
                <>
                  {numeral(student.rating).format('0,0.0')} <Star sx={{ fontSize: theme.typography.body2.fontSize }} />
                </>
              ) : (
                '-'
              )}
            </Typography>
            {isEmpty(filter(studentExperienceBadges, 'isShow')) ? (
              <Typography variant="body2">{jobDetailPageI18n('first_work')}</Typography>
            ) : (
              map(
                studentExperienceBadges,
                ({ isShow, label }, index) =>
                  isShow && (
                    <Typography key={index} variant="body2">
                      {label}
                    </Typography>
                  ),
              )
            )}
          </Box>

          {!isEmpty(student.latestCommends) && (
            <Box>
              <Typography color="primary">{studentFieldI18n('latest_commend')}</Typography>
              <Stack direction="row" flexWrap="wrap" gap={0.5}>
                {map(student.latestCommends, (commend, index) => (
                  <Chip key={index} label={commend} />
                ))}
              </Stack>
            </Box>
          )}
        </DialogContent>
      )}

      <Collapse in={!!internalErrorMessage}>
        <DialogContent>
          <Alert severity="error">{internalErrorMessage}</Alert>
        </DialogContent>
      </Collapse>

      {!disableDetailDialogAction && !isNil(jobApplicantId) && (
        <DialogActions>
          {/*
          <LoadingButton
            variant="outlined"
            color="error"
            fullWidth
            disableElevation
            disabled={isSubmitting}
            loading={isSubmitting}
            onClick={dislikeAppliedStudentHandler}
          >
            {jobDetailPageI18n('dislike_job_applicant')}
          </LoadingButton>
          */}
          <LoadingButton
            variant="contained"
            fullWidth
            disableElevation
            disabled={isSubmitting}
            loading={isSubmitting}
            onClick={acceptAppliedStudentHandler}
          >
            {jobDetailPageI18n('accept_job_applicant')}
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default StudentCandidateDetailDialog;
