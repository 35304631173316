import React from 'react';

import { LocationOff, LocationOn, Map } from '@mui/icons-material';
import type { AutocompleteInputChangeReason } from '@mui/material';
import { Alert, Autocomplete, ListItem, ListItemIcon, ListItemText, Paper, TextField } from '@mui/material';
import { debounce, get, isNil, size, trim } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import { LoadingSpinner, apiAxiosAuth } from '.';

const DEFAULT_OPTIONS = {
  radius: 10000, // Meters
  language: 'th',
};

export type PlaceResult = {
  placeId: string | null;
  location: { latitude: number; longitude: number } | null;
  address: string | null;
  name: string | null;
};

export type PlaceAutocompleteInputProps = {
  onPlaceChange?: (place: PlaceResult) => void;
  locationBias?: { latitude: number; longitude: number };
  autocompleteOptions?: { radius?: number; language?: string };
};

export const PlaceAutocompleteInput = ({
  onPlaceChange,
  locationBias,
  autocompleteOptions,
}: PlaceAutocompleteInputProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [sessionToken, setSessionToken] = React.useState<string | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const [searchText, setSearchText] = React.useState<string | null>(null);
  const [placeOptions, setPlaceOptions] = React.useState<google.maps.places.AutocompleteSuggestion[]>([]);

  const getPlaceAutocomplete = React.useMemo(
    () =>
      debounce(
        async (
          inputText: string,
          session: string,
          location?: typeof locationBias,
          options?: typeof autocompleteOptions,
        ) => {
          setIsLoading(true);
          setErrorMessage(null);

          try {
            const { data } = await apiAxiosAuth('POST', 'google_api_v2/place_autocomplete', {
              data: {
                sessionToken: session,
                input: inputText,
                locationBias: {
                  circle: {
                    center: { latitude: location?.latitude, longitude: location?.longitude },
                    radius: options?.radius ?? DEFAULT_OPTIONS.radius, // Meters
                  },
                },
                languageCode: options?.language ?? DEFAULT_OPTIONS.language,
              },
            });

            setPlaceOptions(get(data, 'suggestions', []));
          } catch (error) {
            setErrorMessage(get(error, 'message', 'Unknown error'));
          }

          setIsLoading(false);
        },
        250,
      ),
    [],
  );

  const onInputChangeHandler = async (
    _event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    setSearchText(value);
    const searchValue = trim(value);

    if (reason === 'clear') {
      setIsLoading(false);
      setPlaceOptions([]);
    }

    // Click on options
    if (reason === 'reset') {
      setIsLoading(false);
      return;
    }

    if (size(searchValue) >= 3) {
      setIsLoading(true);

      let nextSessionToken = sessionToken;
      if (isNil(nextSessionToken)) {
        nextSessionToken = uuidV4();
        setSessionToken(nextSessionToken);
      }

      await getPlaceAutocomplete(searchValue, nextSessionToken, locationBias, autocompleteOptions);
      setIsLoading(false);
    } else {
      getPlaceAutocomplete.cancel();
      setPlaceOptions([]);
      setIsLoading(false);
    }
  };

  // onClickOption
  const onPlaceSuggestionClickHandler = async (
    _event: React.SyntheticEvent<Element, Event>,
    value: google.maps.places.AutocompleteSuggestion | null,
  ) => {
    const placeId = value?.placePrediction?.placeId;

    if (isNil(placeId)) {
      setSessionToken(null);
      return;
    }

    try {
      const { data } = await apiAxiosAuth('GET', `google_api_v2/place_details/${placeId}`, {
        params: {
          sessionToken,
          fields: 'id,displayName,formattedAddress,location',
          languageCode: autocompleteOptions?.language ?? DEFAULT_OPTIONS.language,
        },
      });

      if (data) {
        const latitude = get(data, 'location.latitude');
        const longitude = get(data, 'location.longitude');

        const result = {
          placeId,
          location: latitude && longitude ? { latitude, longitude } : null,
          address: get(data, 'formattedAddress', null),
          name: get(data, 'displayName.text', null),
        };

        if (onPlaceChange) onPlaceChange(result);
      }
    } catch (error) {
      setErrorMessage(get(error, 'message', 'Unknown error'));
    }

    setSessionToken(null);
  };

  return (
    <Paper>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

      <Autocomplete
        id="google-maps-place-autocomplete"
        disablePortal
        clearOnBlur={false}
        loading={isLoading}
        loadingText={
          <ListItem dense>
            <ListItemIcon>
              <LoadingSpinner size={24} color="inherit" BoxProps={{ flex: 0 }} />
            </ListItemIcon>
            <ListItemText primary="กำลังค้นหา" />
          </ListItem>
        }
        noOptionsText={
          <ListItem dense>
            <ListItemIcon>
              <LocationOff />
            </ListItemIcon>
            <ListItemText primary="ไม่พบสถานที่" />
          </ListItem>
        }
        inputValue={searchText ?? ''}
        onInputChange={onInputChangeHandler}
        onChange={onPlaceSuggestionClickHandler}
        options={placeOptions}
        getOptionLabel={(option) => get(option, 'placePrediction.structuredFormat.mainText.text', '')}
        isOptionEqualToValue={(option, value) =>
          get(option, 'placePrediction.placeId') === get(value, 'placePrediction.placeId')
        }
        renderInput={(inputProps) => (
          <TextField
            placeholder="ค้นหาสถานที่"
            error={!!errorMessage}
            sx={{
              'color': '#a2a2a2',
              '&:hover': { '&::placeholder': { color: '#a2a2a2' } },
              '&::placeholder': { color: '#a2a2a2' },
              'backgroundColor': '#a2a2a2',
            }}
            {...inputProps}
            InputProps={{
              ...inputProps.InputProps,
              startAdornment: <Map sx={{ marginX: 1 }} />,
            }}
          />
        )}
        renderOption={(optionProps, option) => (
          <ListItem {...optionProps} dense>
            <ListItemIcon>
              <LocationOn />
            </ListItemIcon>
            <ListItemText
              primary={get(option, 'placePrediction.structuredFormat.mainText.text')}
              secondary={get(option, 'placePrediction.structuredFormat.secondaryText.text')}
            />
          </ListItem>
        )}
      />
    </Paper>
  );
};
