/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const CandidateCardFragment = gql`
  fragment AcceptedJobApplicant on JobApplicant {
    id
    status
    restaurantChargePerHour
    student {
      id
    }
    job {
      id
    }
  }
`;
