import { createTheme } from '@mui/material';
import { light } from '@mui/material/styles/createPalette';

import type { PaletteMode, ThemeOptions } from '@mui/material';

const fontFamily = `"K2D","Roboto","Helvetica","Arial",sans-serif`;
const fontFamilyLarge = `"Mitr","Roboto","Helvetica","Arial",sans-serif`;

const themeTypography: ThemeOptions['typography'] = {
  fontFamily,
  h1: { fontFamily: fontFamilyLarge },
  h2: { fontFamily: fontFamilyLarge },
  h3: { fontFamily: fontFamilyLarge },
  h4: { fontFamily: fontFamilyLarge },
  h5: { fontFamily: fontFamilyLarge },
  h6: { fontFamily: fontFamilyLarge },
};

const lightTheme: ThemeOptions = {
  palette: {
    mode: 'light',

    primary: {
      main: '#4451ff',
    },
  },

  components: {
    MuiTableCell: {
      styleOverrides: {
        root: { borderColor: `color-mix(in srgb, ${light.text.primary} 16%, transparent)` },
      },
    },
  },

  typography: themeTypography,
};

const darkTheme: ThemeOptions = {
  palette: {
    mode: 'dark',

    primary: {
      main: '#bec2ff',
    },
  },

  typography: themeTypography,
};

export const createCustomTheme = (mode: PaletteMode = 'light') => {
  const theme = createTheme(mode === 'dark' ? darkTheme : lightTheme);
  return theme;
};

export const mapLightTheme = [];

export const mapDarkTheme = [
  {
    elementType: 'geometry',
    stylers: [{ color: '#242f3e' }],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ color: '#746855' }],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#242f3e' }],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#263c3f' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#6b9a76' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#38414e' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#212a37' }],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9ca5b3' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#746855' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#1f2835' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#f3d19c' }],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#2f3948' }],
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#17263c' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#515c6d' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#17263c' }],
  },
];
