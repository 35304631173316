import React from 'react';

import { ApolloError, gql, useMutation } from '@apollo/client';
import { NotificationsActive } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogTitle, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { getApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { get, isNil } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { APP_NAME, FCM_VAPID_KEY } from '../config';
import { i18n } from '../i18n/i18n';
import { notificationDataToRouteMap } from '../utils/libs';

const registerNotificationPushTokenGql = gql(`
  mutation registerNotificationPushToken($channelId: String!, $token: ID!, $tokenType: NOTIFICATION_PUSH_TOKEN_TYPE_OPTIONS!) {
    registerNotificationPushToken(channelId: $channelId, token: $token, tokenType: $tokenType) {
      success
      errors
    }
  }
`);

const unregisterNotificationPushTokenGql = gql(`
  mutation unregisterNotificationPushToken($token: ID!) {
    unregisterNotificationPushToken(token: $token) {
      success
      errors
    }
  }
`);

type LocalNotificationTokenStatus = {
  token?: string | null;
  checkAt?: string | null;
  isSurelyFirebaseServiceWorker?: boolean;
};

const getLocalNotiTokenStatus = () => {
  const notificationTokenStatusJson = localStorage.getItem('NOTIFICATION_TOKEN_STATUS');

  let notificationTokenStatus: LocalNotificationTokenStatus | null = null;
  if (notificationTokenStatusJson) {
    notificationTokenStatus = JSON.parse(notificationTokenStatusJson);
  }
  return notificationTokenStatus;
};

const setLocalNotiTokenStatus = (
  status: LocalNotificationTokenStatus,
  { autoUpdateCheckAtInStatus } = { autoUpdateCheckAtInStatus: true },
) => {
  const newStatus = autoUpdateCheckAtInStatus ? { ...status, checkAt: dayjs().toISOString() } : status;
  localStorage.setItem('NOTIFICATION_TOKEN_STATUS', JSON.stringify(newStatus));
};

const Notifications = () => {
  const serviceWorkerRegistrationRef = React.useRef<ServiceWorkerRegistration>();
  const [isServiceWorkerReady, setIsServiceWorkerReady] = React.useState(false);

  React.useEffect(() => {
    if ('serviceWorker' in navigator) {
      // Get registered PCM+PWA service worker for FCM getToken
      navigator.serviceWorker.getRegistration('/').then((serviceWorkerRegistration) => {
        if (serviceWorkerRegistration) {
          serviceWorkerRegistrationRef.current = serviceWorkerRegistration;
          setIsServiceWorkerReady(true);
        }
      });
    }
  }, []);

  const getNotificationToken = async () => {
    if (!('serviceWorker' in navigator)) {
      return null;
    }

    const app = getApp();
    const messaging = getMessaging(app);
    const notificationToken = await getToken(messaging, {
      vapidKey: FCM_VAPID_KEY,
      serviceWorkerRegistration: serviceWorkerRegistrationRef.current,
    });

    return notificationToken;
  };

  const [registerNotificationPushTokenMutation] = useMutation(registerNotificationPushTokenGql);
  const registerNotificationToken = async () => {
    const notificationToken = await getNotificationToken();

    if (notificationToken) {
      try {
        const { data } = await registerNotificationPushTokenMutation({
          variables: {
            channelId: APP_NAME,
            token: notificationToken,
            tokenType: 'web',
          },
        });

        if (data?.registerNotificationPushToken.success) {
          setLocalNotiTokenStatus({
            token: notificationToken,
            isSurelyFirebaseServiceWorker: true,
          });
        } else {
          console.warn('registerNotificationToken', data?.registerNotificationPushToken?.errors);
        }
      } catch (error) {
        if (!(error instanceof ApolloError)) throw error;
      }
    }
  };

  const [unregisterNotificationPushTokenMutation] = useMutation(unregisterNotificationPushTokenGql);
  const unregisterNotificationToken = async () => {
    const notiTokenStatus = getLocalNotiTokenStatus();

    if (notiTokenStatus?.token) {
      try {
        const { data } = await unregisterNotificationPushTokenMutation({
          variables: { token: notiTokenStatus.token },
        });

        if (data?.unregisterNotificationPushToken.success) {
          localStorage.removeItem('NOTIFICATION_TOKEN_STATUS');
        } else {
          console.warn('unregisterNotificationToken', data?.unregisterNotificationPushToken?.errors);
        }
      } catch (error) {
        if (!(error instanceof ApolloError)) throw error;
      }
    }
  };

  const [showNotiPermissionDialog, setShowNotiPermissionDialog] = React.useState(false);
  const deniedNotiPermissionHandler = async () => {
    setShowNotiPermissionDialog(false);
    await unregisterNotificationToken();
    setLocalNotiTokenStatus({ token: null });
  };

  React.useEffect(() => {
    if (!isServiceWorkerReady) return;

    const notiTokenStatus = getLocalNotiTokenStatus();

    if (
      isNil(notiTokenStatus?.token) &&
      (isNil(notiTokenStatus?.checkAt) || dayjs(/* now */).isAfter(dayjs(notiTokenStatus?.checkAt).add(3, 'days')))
    ) {
      if ('serviceWorker' in navigator && 'Notification' in window && Notification.permission !== 'denied') {
        setShowNotiPermissionDialog(true);
      }
    }
  }, [isServiceWorkerReady]);

  // Logic to check and re-regis token status is old and different to current one
  React.useEffect(() => {
    if (!isServiceWorkerReady) return;

    const reRegisterNotiToken = async () => {
      const notiTokenStatus = getLocalNotiTokenStatus();

      // Have token before fix
      if (notiTokenStatus?.token && !notiTokenStatus.isSurelyFirebaseServiceWorker) {
        if (notiTokenStatus.token !== (await getNotificationToken())) {
          // Bugged token re-regis new token
          await unregisterNotificationToken();
          await registerNotificationToken();
        } else {
          // Have normal token add fix flag
          setLocalNotiTokenStatus(
            { ...notiTokenStatus, isSurelyFirebaseServiceWorker: true },
            { autoUpdateCheckAtInStatus: false },
          );
        }
      }
    };

    reRegisterNotiToken();
  }, [isServiceWorkerReady]);

  const navigate = useNavigate();

  // Handle foreground notification
  React.useEffect(() => {
    if (!('serviceWorker' in navigator)) {
      return;
    }

    const app = getApp();
    const messaging = getMessaging(app);
    const unsubscribe = onMessage(messaging, (payload) => {
      // Foreground Message goes here
      if (payload.notification?.title) {
        const notificationDataJson = get(payload, ['data', 'data'], null);
        let notificationData: { [key: string]: unknown } | null = null;
        try {
          notificationData = JSON.parse(notificationDataJson);
        } catch (error) {
          notificationData = null;
        }
        const { routePath, isExternal, params } = notificationDataToRouteMap(notificationData);

        const notification = new Notification(payload.notification.title, {
          body: payload.notification.body,
          data: payload.data,
        });

        // Add click event handler for foreground notifications
        notification.onclick = () => {
          if (routePath) {
            if (isExternal) {
              window.open(routePath, '_blank');
            } else {
              navigate(routePath);
            }
          }
        };
      }
    });

    // eslint-disable-next-line consistent-return
    return unsubscribe;
  }, [navigate]);

  const requestNotificationPermissionHandler = async () => {
    setShowNotiPermissionDialog(false);

    const result = await Notification.requestPermission();
    if (result === 'granted') {
      registerNotificationToken();
    }
  };

  return (
    <Dialog open={showNotiPermissionDialog} onClose={deniedNotiPermissionHandler}>
      <DialogTitle>
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <NotificationsActive />
          {i18n.t('components.notifications.notification_permission_request')}
        </Stack>
      </DialogTitle>

      <DialogActions>
        <Button onClick={deniedNotiPermissionHandler}>{i18n.t('general.denied')}</Button>
        <Button onClick={requestNotificationPermissionHandler}>{i18n.t('general.accept')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Notifications;
